.blogs {
	padding: 40px 0;
	padding-bottom: 0;
	// width: 100%;
	.title {
		text-align: center;
		color: #272876;
		span {
			color: #20222e;
		}
	}
	.desc {
		font-size: 16px;
		font-family: "Plus Jakarta Sans";
		color: #9999b8;
		text-align: center;
		padding: 0 20px;
	}
	.feature_container {
		.para_title {
			font-size: 36px;
			font-weight: bold;
			color: #272876;
		}

		.container {
			padding: 20px;
			.image_name_container {
				cursor: pointer;
				box-shadow: -4px 4px 10px 0px #3f4ff81a;
				border-radius: 20px;
				min-height: 300px;
				.image_container {
					border-radius: 20px;
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 0;
					height: 180px;
					width: 100%;
					display: flex;
					justify-content: center;
					border-radius: 20px;
					align-items: center;
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
						border-radius: 20px;
						border-bottom-left-radius: 0;
						border-bottom-right-radius: 0;
					}
				}
				.textArea {
					padding: 20px;
					background-color: #fff;
					border-radius: 20px;
					border-top-left-radius: 0;
					border-top-right-radius: 0;
					.top {
						display: flex;
						justify-content: space-between;
						align-items: center;
						font-size: 10px;
						.tech {
							color: #8181ac;
						}
						.time {
							color: #8181ac;
						}
					}
					.name {
						margin-top: 20px;
						font-size: 16px !important;
						color: #282876 !important;
						font-family: "Plus Jakarta Sans";
						font-weight: bold;
					}
					.read_more_container {
						display: flex;
						align-items: center;
						// justify-content: center;
						span {
							margin: 0;
							display: block;
							margin-right: 5px;
						}
					}
					.read_more {
						color: #20222e;
						margin-top: 20px;
						font-size: 12px;
						text-align: right;
						font-weight: bold;
					}
				}
			}
		}

		.latest_container {
			padding: 20px;
			.image_name_container {
				cursor: pointer;
				box-shadow: -4px 4px 10px 0px #3f4ff81a;
				border-radius: 20px;
				.image_container {
					border-radius: 20px;
					width: 100%;
					display: flex;
					justify-content: center;
					border-radius: 20px;
					align-items: center;
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
						border-radius: 20px;
						border-bottom-left-radius: 0;
						border-bottom-right-radius: 0;
					}
				}
				.text_area {
					display: flex;
					flex-direction: column;
					justify-content: flex-end;
					padding: 20px;
					background-color: #fff;
					border-radius: 20px;
					border-top-left-radius: 0;
					border-top-right-radius: 0;
					.text_area_container {
						display: flex;
						flex-direction: column;
						height: 100%;
						color: #282876;
						.content {
							color: #8181ac;
						}
						.date {
							font-size: 12px;
						}
					}
					.blog_title {
						font-size: 20px;
						color: #272876;
						font-weight: bold;
						font-family: "Plus Jakarta Sans";
						margin: 20px 0;
					}
					.top {
						display: flex;
						justify-content: space-between;
						align-items: center;
						font-size: 10px;
						.tech {
							color: #8181ac;
						}
						.time {
							color: #8181ac;
						}
					}
					.name {
						margin-top: 20px;
						font-size: 16px !important;
						color: #282876 !important;
						font-family: "Plus Jakarta Sans";
						font-weight: bold;
					}
					.read_more_container {
						display: flex;
						align-items: center;
						// justify-content: center;
						span {
							margin: 0;
							display: block;
							margin-right: 5px;
						}
					}
					.read_more {
						color: #20222e;
						margin-top: 20px;
						font-size: 12px;
						text-align: right;
						font-weight: bold;
					}
				}
			}
		}
	}

	@media (max-width: 768px) {
		margin: 0;
		.feature_container {
			.scroll_on_mobile {
				overflow-x: auto;
				flex-wrap: nowrap;
				gap: 0;
				margin: 0 !important;
				.container {
					padding: 20px;
					.image_name_container {
						width: 80vw;
					}
				}
			}
		}
	}
}

.blog_details {
	.blog_container {
		padding: 40px 20px;
		border-bottom: 1px solid #282876;
		.topic {
		}
		.desc {
			font-size: 16px;
			font-family: "Plus Jakarta Sans";
			color: #8181ac;
			padding: 20px 0;
			margin: 0;
		}
		.author {
			color: #20222e;
			font-style: italic;
		}
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	.feature_container {
		padding: 20px 0;
		.para_title {
			font-size: 36px;
			font-weight: bold;
			color: #272876;
			.other_blogs {
				font-size: 36px;
				text-align: center;
				color: #272876;
				span {
					color: #20222e;
				}
			}
		}

		.container {
			padding: 20px;
			.image_name_container {
				cursor: pointer;
				box-shadow: -4px 4px 10px 0px #3f4ff81a;
				border-radius: 20px;
				min-height: 300px;
				.image_container {
					border-radius: 20px;
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 0;
					height: 180px;
					width: 100%;
					display: flex;
					justify-content: center;
					border-radius: 20px;
					align-items: center;
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
						border-radius: 20px;
						border-bottom-left-radius: 0;
						border-bottom-right-radius: 0;
					}
				}
				.textArea {
					padding: 20px;
					background-color: #fff;
					border-radius: 20px;
					border-top-left-radius: 0;
					border-top-right-radius: 0;
					.top {
						display: flex;
						justify-content: space-between;
						align-items: center;
						font-size: 10px;
						.tech {
							color: #8181ac;
						}
						.time {
							color: #8181ac;
						}
					}
					.name {
						margin-top: 20px;
						font-size: 16px !important;
						color: #282876 !important;
						font-family: "Plus Jakarta Sans";
						font-weight: bold;
					}
					.read_more_container {
						display: flex;
						align-items: center;
						// justify-content: center;
						span {
							margin: 0;
							display: block;
							margin-right: 5px;
						}
					}
					.read_more {
						color: #20222e;
						margin-top: 20px;
						font-size: 12px;
						text-align: right;
						font-weight: bold;
					}
				}
			}
		}

		.latest_container {
			padding: 20px;
			.image_name_container {
				cursor: pointer;
				box-shadow: -4px 4px 10px 0px #3f4ff81a;
				border-radius: 20px;
				.image_container {
					border-radius: 20px;
					width: 100%;
					display: flex;
					justify-content: center;
					border-radius: 20px;
					align-items: center;
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
						border-radius: 20px;
						border-bottom-left-radius: 0;
						border-bottom-right-radius: 0;
					}
				}
				.text_area {
					display: flex;
					flex-direction: column;
					justify-content: flex-end;
					padding: 20px;
					background-color: #fff;
					border-radius: 20px;
					border-top-left-radius: 0;
					border-top-right-radius: 0;
					.text_area_container {
						display: flex;
						flex-direction: column;
						height: 100%;
						color: #282876;
						.content {
							color: #8181ac;
						}
						.date {
							font-size: 12px;
						}
					}
					.blog_title {
						font-size: 20px;
						color: #272876;
						font-weight: bold;
						font-family: "Plus Jakarta Sans";
						margin: 20px 0;
					}
					.top {
						display: flex;
						justify-content: space-between;
						align-items: center;
						font-size: 10px;
						.tech {
							color: #8181ac;
						}
						.time {
							color: #8181ac;
						}
					}
					.name {
						margin-top: 20px;
						font-size: 16px !important;
						color: #282876 !important;
						font-family: "Plus Jakarta Sans";
						font-weight: bold;
					}
					.read_more_container {
						display: flex;
						align-items: center;
						// justify-content: center;
						span {
							margin: 0;
							display: block;
							margin-right: 5px;
						}
					}
					.read_more {
						color: #20222e;
						margin-top: 20px;
						font-size: 12px;
						text-align: right;
						font-weight: bold;
					}
				}
			}
		}
	}

	@media (max-width: 768px) {
		margin: 0;
	}
}
