.footer {
	background-color: #287A90;
	color: white;
	// padding: 40px;
	.left_area {
		h1 {
			font-size: 40px;
			font-weight: bold;
			margin-bottom: 20px;
		}
		.blue_text {
			color: #fff;
		}
		.desc {
			font-size: 16px;
			margin-top: 20px;
			margin-bottom: 20px;
			color: #8181ac;
			display: block;
			@media (max-width: 768px) {
				padding: 0;
				text-align: justify;
			}
		}
		.button_style {
			background-color: #fff;
			color: #20222e;
		}
	}
	.right_area {
		text-align: center;
		.visit-card {
			background-color: #fff;
			border-radius: 10px;
			padding: 20px;
			color: #20222e;
			.name {
				font-size: 20px;
				font-weight: bold;
			}
			.role {
				font-size: 16px;
				color: #8181ac;
			}
			.mobile {
				margin-top: 20px;
			}
		}
	}
	.bottom_area {
		border-top: 1px solid #20222e;
		// margin-top: 40px;
		.left_area {
			padding-top: 20px;
			background-color: #124353;
			.icon {
				font-size: 25px;
				color: #9999b8;
				margin-top: 20px;
			}
			a {
				@media (max-width: 768px) {
					font-size: 16px;
				}
			}
		}
		.name {
			font-size: 25px;
			font-weight: bold;
			color: #fff;
		}
		.info-email {
			display: block;
			margin-top: 20px;
			font-size: 12px;
			color: #9999b8;
		}
		span {
			display: block;
			margin-top: 20px;
			font-size: 12px;
			color: #9999b8;
		}

		.right_area {
			display: flex;
			align-items: flex-end;
			flex-direction: column;
			padding-top: 20px;
			.links {
				list-style: none;
				padding: 0;
				margin: 0;
				display: flex;
				align-self: flex-end;
				gap: 20px;
				color: #fff;
				a {
					text-decoration: none;
					color: #fff;

					@media (max-width: 768px) {
						font-size: 16px;
					}
				}
			}
		}
	}

	@media (max-width: 768px) {
		.right_area {
			display: none;
		}
		.bottom_area {
			font-size: 16px;
			.right_area {
				display: none;
			}
		}
	}
}
