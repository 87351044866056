.contact_us {
	padding: 40px 0;
	.title {
		text-align: center;
		color: #272876;
		span {
			color: #20222e;
		}
	}
	.desc {
		font-size: 16px;
		font-family: "Plus Jakarta Sans";
		color: #9999b8;
		text-align: center;
		padding: 0 20px;
	}
	.input_area {
		.left_area {
			background-color: #20222e;
			// min-height: 90vh;
			border-radius: 20px;
			.input_container {
				padding: 40px 30px;
				.contact_info {
					font-size: 24px;
					color: #fff;
					font-weight: bold;
				}
				.contact_desc {
					margin: 0;
					padding: 0;
					color: #ffffff99;
					line-height: 20px;
					margin-top: 10px;
					font-size: 16px;
				}
				.links {
					margin: 0;
					list-style: none;
					padding: 0;
					color: #fff;
					padding-top: 20px;
					font-size: 16px;
					li {
						margin-bottom: 20px;
						display: flex;
						align-items: center;
						.icon_container {
							background-color: #fff;
							border-radius: 50%;
							padding: 5px;
							width: 30px;
							height: 30px;
							display: flex;
							justify-content: center;
							align-items: center;
							margin-right: 20px;
							img {
								width: 20px;
								height: 20px;
							}
						}
					}
				}
			}
		}
		.right_area {
			.text_input_area {
				padding: 40px 30px;
				.input {
					display: flex;
					flex-direction: column;
					label {
						color: #8181ac;
						font-size: 16px;
						font-weight: 600;
						font-family: "Plus Jakarta Sans";
					}
					input {
						padding: 20px 0;
						border: none;
						border-bottom: 1px solid #e0e0e0;
						outline: none;
						font-family: "Plus Jakarta Sans";
						font-size: 16px;
					}
				}
				.button_container {
					display: flex;
					justify-content: flex-end;
					margin-top: 20px;
					button {
						border-radius: 100px;
						min-height: 40px;
						padding: 0 40px;
						transition: all 0.3s ease;
						&:hover {
							// background-color: #272876;
						}
					}
				}
			}
			.what_service {
				.label_style {
					color: #282876;
					font-size: 16px;
					font-weight: 600;
				}
				.service_selection {
					margin: 0;
					padding: 0;
					list-style: none;
					display: grid;
					grid-template-columns: repeat(6, 1fr);
					padding-top: 20px;
					.check_container {
						display: flex;
						align-items: center;
						margin-right: 20px;
						label {
							cursor: pointer;
						}
						img {
							margin-right: 10px;
						}
					}
				}
				.service_selection_3 {
					grid-template-columns: repeat(3, 1fr);
				}
			}
		}
	}

	@media (max-width: 768px) {
		margin: 0;
		.input_area {
			.left_area {
				display: none;
			}
			.right_area {
				.text_input_area {
					.input_area {
						width: 100%;
					}
					.button_container {
						margin-top: 0;
					}
				}
				.what_service {
					.service_selection {
						grid-template-columns: repeat(3, 1fr);
					}
					.service_selection_3 {
						grid-template-columns: repeat(1, 1fr);
						gap: 10px;
					}
				}
				.service_selection {
					grid-template-columns: repeat(3, 1fr);
					gap: 10px;
				}
			}
		}
	}
}
