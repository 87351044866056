.navbar {
	height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.2rem;
	color: #20222e;
	font-family: "Plus Jakarta Sans", sans-serif;
	font-size: 16px;
	box-shadow: 0px 4px 10px 0px #28287640;
}

a {
	color: #20222e;
}

.navbar-logo {
	width: 150px;
	height: 100px;
	/* height: 150px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: white; */

	text-align: center;
	justify-self: start;
	/* margin-left: 20px; */
	cursor: pointer;
	text-decoration: none;
	font-size: 2rem;
}

.navbar-logo-img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.fa-firstdraft {
	margin-left: 0.5rem;
	font-size: 1.6rem;
}

.nav-menu {
	display: grid;
	grid-template-columns: repeat(5, auto);
	grid-gap: 10px;
	list-style: none;
	text-align: center;
	width: 70vw;
	justify-content: flex-end;
	margin-right: 2rem;
	background-color: #fff;
	/* margin: 0; */
}

.nav-item {
	display: flex;
	align-items: center;
	/* height: 80px; */
}

.nav-links {
	text-decoration: none;
	padding: 0.5rem 1rem;
}

.nav-links.active {
	color: #20222e;
}

.submenu-clicked a {
	color: #20222e;
}

.submenu-clicked::after {
	border-bottom-color: #3f4ff8 !important;
}

.nav-links:hover {
	/* background-color: #1888ff; */
	border-radius: 4px;
	transition: all 0.2s ease-out;
	text-decoration: underline;
}

.fa-bars {
	color: #20222e;
}

.nav-links-mobile {
	display: none;
}

.menu-icon {
	display: none;
}

@media (max-width: 768px) {
	.nav-menu {
		padding-bottom: 100px;
	}
}
@media screen and (max-width: 960px) {
	.NavbarItems {
		position: relative;
	}

	.nav-menu {
		padding: 0;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		width: 100%;
		height: calc(100vh - 100px);
		position: absolute;
		top: 80px;
		left: -100%;
		opacity: 1;
		transition: all 0.5s ease;
		overflow-y: scroll;
	}

	.nav-menu.active {
		background: #fff;
		left: 0;
		opacity: 1;
		transition: all 0.5s ease;
		z-index: 1;
	}

	.nav-links {
		text-align: left;
		padding: 10px 40px;
		width: 100%;
		display: table;
	}

	.nav-links:hover {
		/* background-color: #1888ff; */
		border-radius: 0;
	}

	.navbar-logo {
		position: absolute;
		top: 0;
		left: 0;
		transform: translate(200%, 10%);
		width: 80px;
		height: 80px !important;
		display: block !important;
	}

	.menu-icon {
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(-100%, 60%);
		font-size: 1.8rem;
		cursor: pointer;
	}

	.fa-times {
		color: #20222e;
		font-size: 2rem;
	}

	.nav-links-mobile {
		display: block;
		/* text-align: center; */
		/* padding: 1.5rem; */
		/* margin: 2rem auto; */
		border-radius: 4px;
		width: 80%;
		/* background: #1888ff;
		text-decoration: none;
		color: #fff; */
		/* font-size: 1.5rem; */
		color: #20222e;
	}

	.nav-links-mobile:hover {
		background: #fff;
		color: #1888ff;
		transition: 250ms;
	}

	.btn {
		display: none;
	}
}
