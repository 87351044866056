.top_section {
	// background-image: url('../../assets/images/home-back.png');
	background-repeat: no-repeat;
	min-height: 50vh;
	padding: 20px;
	@media (max-width: 768px) {
		background-image: none;
	}
	.banner_wrap {
		background-image: url('../../assets/images/image.jpg');
		background-color: transparent;
		background-position: 100%;
		background-size: cover;
		color: #fff;
		.container {
			min-height: 30rem;
			.left_container {
				display: flex;
				align-items: center;
				justify-items: center;
				.content {
					padding: 24px;
					width: 100%;
					.heading_title {
						text-align: center;
						background: #000;
						padding: 20px;
					}
					.download_container {
						text-align: center;
						padding-top: 20px;
						img {
							width: 150px;
							// height: 150px;
							object-fit: contain;
							cursor: pointer;
						}
					}
				}
			}
			.right_container {
				width: 100%;
				padding: 20px;
				display: flex;
				align-items: center;
				justify-content: center;
				.input_container {
					background-color: #fff;
					border-radius: 4px;
					padding: 20px;
					display: flex;
					flex-direction: column;
					gap: 20px;
					.title_row {
						.title {
							font-size: 20px;
							font-weight: 600;
							color: #000;
						}
						img {
							width: 40px;
							height: 40px;
						}
					}
					input {
						border: 1px solid #e0e3eb;
						padding-left: 1.25rem;
						padding-right: 1.25rem;
						padding-bottom: 0.95rem;
						padding-top: 0.95rem;
						font-size: 16px;
						line-height: 1;
					}
					.otp-button {
						background-color: #101218;
						text-align: center;
						padding-left: 1.25rem;
						padding-right: 1.25rem;
						padding-bottom: 0.95rem;
						padding-top: 0.95rem;
						font-size: 16px;
						line-height: 1;
						background-image: none;
						outline: none;
						border: none;
						color: #fff;
						border-radius: 4px;
						font-weight: bold;
						cursor: pointer;
					}
				}
			}
		}
	}
	@media (max-width: 768px) {
		padding: 20px;

		.container {
			.title {
				font-size: 12px;
			}
			h1,
			.Typewriter__wrapper {
				font-size: 32px;
				text-align: center;
			}
			.animate_text_area {
				flex-direction: column;
			}
		}
	}
}
