.services {
	.feature_container {
		.container {
			padding: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			.text_container {
				box-shadow: -4px 4px 5px 0px #28287626;
				border-radius: 20px;
				background-color: #f3f3f3;
				width: 300px;
				height: 300px;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				padding: 40px 20px;
				box-sizing: border-box;
				position: relative;
				overflow: hidden;
				cursor: pointer;
				transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
				.description {
					color: #8181ac;
					margin-top: 20px;
					font-size: 16px;
				}
				ul {
					margin-top: 20px;
					padding-left: 20px;
					li {
						font-size: 14px;
						font-weight: 600;
						margin-bottom: 10px;
						color: #fff;
						font-family: "Plus Jakarta Sans";
					}
				}
				&:hover {
					background-color: #272876;
					.description,
					.title {
						color: #fff;
					}
				}
			}
		}
		.mobile_view {
			display: none;
			.collapsible_container {
				// padding: 20px;
				margin: 20px;
				// border: 1px solid #282876;
				box-shadow: 0px 2px 4px 0px #28287626;
				border-radius: 20px;
				.Collapsible__trigger {
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 20px;
				}
				.Collapsible__trigger.is-open svg {
					transform: rotate(180deg);
				}
				.Collapsible__contentOuter {
					background: rgba(40, 40, 118, 1);
					color: #fff;
					border-radius: 20px;
					padding: 0 20px;
				}
				.Collapsible {
					span,
					svg {
						// display: block;
						// width: 100%;
						color: #282876;
						font-size: 18px;
						font-weight: 700;
					}
				}
				p {
					font-family: "Plus Jakarta Sans";
					margin-bottom: 0;
					font-size: 16px;
				}
				ul {
					padding-left: 20px;
					font-size: 16px;
					font-family: "Plus Jakarta Sans";
				}
			}
		}
	}

	.footer_text {
		padding: 20px 0;
		text-align: center;
		color: #282876;
		font-size: 24px;
		font-weight: 600;
		span {
			color: #20222e;
		}
		@media (max-width: 768px) {
			font-size: 16px;
			padding: 20px;
			padding-bottom: 0;
			margin-top: 0;
		}
	}

	@media (max-width: 768px) {
		margin: 0;
		.feature_container {
			.web_view {
				display: none;
			}
			.mobile_view {
				display: block;
			}
			.scroll_on_mobile {
				overflow-x: auto;
				flex-wrap: nowrap;
				gap: 0;
				margin: 0 !important;
				.container {
					padding: 20px;
					.image_name_container {
						width: 80vw;
					}
				}
			}
		}
	}
}
